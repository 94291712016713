import { EventEmitter, Injectable, Output } from '@angular/core';
import { GetTaxiService } from './get-taxi.service';
import { ParameterService } from './parameter.service';
import { Subscription } from 'rxjs';
import { RideType } from '../models/enums';

@Injectable({
  providedIn: 'root'
})
export class TaxiConfigurationService {

  @Output() canShareTaxi = new EventEmitter<boolean>();
  canShareTaxiValue = false;

  @Output() taxiName = new EventEmitter<string>();
  taxiNameValue = '';

  @Output() taxiHotelNumber = new EventEmitter<string>();
  taxiHotelNumberValue = '';

  @Output() taxiGuestNumber = new EventEmitter<string>();
  taxiGuestNumberValue = '';

  @Output() taxiAvailableOptions = new EventEmitter<string[]>();
  taxiAvailableOptionValues = [];

  @Output() extraContactInformation = new EventEmitter<string>();
  extraContactInformationValue = '';

  constructor(private getTaxiService: GetTaxiService, private parameterService: ParameterService) {
    this.updateConfig();
  }

  private updateFromConfig = (config: any) => {
    this.canShareTaxiValue = config.canShareTaxi;
    this.taxiNameValue = config.taxiName;
    this.taxiHotelNumberValue = config.taxiHotelPhoneNumber;
    this.taxiGuestNumberValue = config.taxiGuestPhoneNumber;
    this.taxiAvailableOptionValues = config.availableOptions;
    this.extraContactInformationValue = config.extraContactInformation;

    this.canShareTaxi.emit(config.canShareTaxi);
    this.taxiName.emit(config.taxiName);
    this.taxiHotelNumber.emit(config.taxiHotelPhoneNumber);
    this.taxiGuestNumber.emit(config.taxiGuestPhoneNumber);
    this.taxiAvailableOptions.emit(config.availableOptions);
    this.extraContactInformation.emit(config.extraContactInformation);
  };

  parameterServiceSubscription?: Subscription;
  private updateConfig = (priorityGroup?: RideType) => {
    if (this.parameterServiceSubscription) this.parameterServiceSubscription!.unsubscribe();

    this.parameterServiceSubscription = this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      if (params.hotelId)
        this.getTaxiService.getTaxiConfiguration(params.hotelId, priorityGroup)
          .subscribe(this.updateFromConfig);
      else if (params.rideId)
        this.getTaxiService.getTaxiConfigurationByRide(params.rideId)
          .subscribe(this.updateFromConfig);
    });
  }

  updatePriorityGroup(priorityGroup: RideType) {
    this.updateConfig(priorityGroup);
  }
}
