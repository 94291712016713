<ion-content>
    <app-header></app-header>
    <div class="taxiHotel">
        <div class="container"
            [hidden]="showChild">
            <form class="orderForm">
                <mat-form-field class="form-group search">
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput
                        placeholder="{{'overviewbill' | translate}}"
                        [(ngModel)]="query"
                        name="searchId">
                </mat-form-field>
            </form>

            <div class="monthhead">
                <ul>
                    <li>
                        <button mat-mini-fab
                                class="customBtn"
                                (click)="prevDate()">
                            <mat-icon>arrow_back</mat-icon>
                        </button>
                    </li>
                    <li>
                        <span class="text">{{controlDate | date:'MMMM y'}}</span>
                    </li>
                    <li>
                        <button mat-mini-fab
                                class="customBtn"
                                (click)="addDate()">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="monthdata">
                <div class="group">
                    <div class="item"
                        *ngFor="let receipt of reciepts| searchFilter: query"
                        [ngClass]="{'notadded': !isManual && !(receipt.applied || receipt.deleted || receipt.prepaid),
                        'notadded-manual': isManual && !(receipt.applied || receipt.deleted || receipt.prepaid),
                        'awaiting-payment-manual': isManual && receipt.prepaid,
                        'canceled-manual': isManual && receipt.deleted}">

                        <span *ngIf="isManual && !(receipt.applied || receipt.deleted || receipt.prepaid)" class="item-title-manual"><b>Bill to the guest's room</b></span>
                        <span *ngIf="isManual && receipt.applied && compareDateTime(receipt.rides[0].pickUpTime)" class="item-title-manual">In Progress</span>
                        <span *ngIf="isManual && (receipt.applied && !receipt.prepaid) && !compareDateTime(receipt.rides[0].pickUpTime)" class="item-title-manual">Completed & Paid</span>
                        <span *ngIf="isManual && receipt.prepaid" class="item-title-manual"><b>Awaiting Payment to Taxi Driver</b></span>
                        <span *ngIf="isManual && receipt.deleted" class="item-title-manual"><b>Guest cancelled the ride!</b></span>

                        <div class="row">
                            <div class="box">
                                <h3 *ngIf="receipt?.bookingNumber">{{'resNo' | translate}}: {{receipt.bookingNumber}}</h3>
                                <h3 *ngIf="receipt?.roomNumber">{{'roomNo2' | translate}}: {{receipt.roomNumber}}</h3>
                                <h3 *ngIf="receipt?.guestName">{{'guestName' | translate}}: {{receipt.guestName}}</h3>
                                <ul class="info">
                                    <li>{{'tel' | translate}}. {{receipt.phoneNumber}}</li>
                                </ul>
                            </div>
                            <div class="box"
                                *ngIf="receipt.applied">
                                <span *ngIf="!isManual" class="status">{{'isAddedToRoomcopy' | translate}}</span>
                            </div>
                            <div class="box"
                                *ngIf="receipt.prepaid">
                                <span *ngIf="!isManual" class="status">{{'isPrepaid' | translate}}</span>
                            </div>
                            <div class="box"
                                *ngIf="receipt.deleted">
                                <span *ngIf="!isManual" class="status">{{'isDeletedFromRoom' | translate}}</span>
                            </div>
                            <div class="box"
                                *ngIf="!receipt.applied && !receipt.deleted && !receipt.prepaid"></div>

                            <div class="box">
                                <span class="price">{{receipt.total}} {{currency}}</span>
                                <button mat-flat-button
                                        (click)="goToBill(receipt)">
                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="childBillContainer"
            [hidden]="!showChild">
            <app-bill [isManual]=isManual [room]="selectedRoom"
                    (backCliedEvent)="setActiveAndReload()"></app-bill>
        </div>
    </div>
</ion-content>
