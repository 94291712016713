<ion-content>
  <div class="container"> 
    <app-station-header [logoSrc]="hotelInfo?.logoSrc" [language]="hotelInfo?.language"></app-station-header>

    <div class="bottom-left">
      <app-coin-tree-counter-container></app-coin-tree-counter-container>
    </div>

    <div class="left-container">
      <div class="taxiHotel">
        <div class="tabs">
          <form [formGroup]="orderFormGroup"
            #orderFormGroupForm="ngForm"
            class="orderOptions">
            <div class="rows">
              <div class="order-button">
                <div class="order-submit-wrapper">
                  <button mat-flat-button
                          class="order-submit"
                          [disabled]="!orderFormGroup.valid"
                          (click)="submit()"
                          color="primary">{{ "selfServiceStation_orderButtonText" | translate }}</button>
                </div>      
              </div>
  
              <div class="pick-time-and-price">
                  <div>
                    <label class="title">{{ "guestPickedUp" | translate }}</label>
                    <div>
                      <app-date-time-picker formControlName="pickupTime"
                                            [autoIncrementTime]="true"
                                            (change)="onChangeDate()"></app-date-time-picker>
                    </div>
                  </div>
              </div>
  
              <div class="identicfication">
                <ion-label class="title">{{ "identification" | translate }}</ion-label>
                <ion-input formControlName="guestName"
                          type="text"
                          [placeholder]="translate.instant('roomnumber_short')"
                          class="form-controls" input-placeholder></ion-input>
              </div>
            </div>
            
            <div class="rows">
              <div class="phoneNumber" *ngIf="showPhone()">
                <ion-label class="title">{{ "phoneNoLabel" | translate }}</ion-label>
                <app-phone-number-input-pc 
                  type="button"
                  class="phone-number"
                  (onPressNumber)="phoneSelect($event)"
                  [hotelId]="hotelId">
                </app-phone-number-input-pc>
              </div>

              <div class="taxiInfo">
                <div class="options-table" *ngIf="displayOption('large')">
                  <mat-checkbox aria-label="Select an option"
                                formControlName="largeTaxi"
                                color="primary">
                    <span class="mat-checkbox-label">
                      {{ largeTaxiTranslation }}
                    </span>
                  </mat-checkbox>
                </div>
              </div>

            </div>
          </form>

          <div class="upcomming-rides-div">
            <div class="no-rides-planned" *ngIf="rides.length === 0">
              {{ 'restaurantNoRides' | translate }}
          </div>
          <div *ngIf="rides.length > 0" class="upcomming-rides">
              <table>
                <thead>
                  <tr>
                    <th class="table-cancel-width"></th>
                    <th class="round-rectangle">{{ 'pickUpTime' | translate }}</th>
                    <th class="round-rectangle">{{ 'status' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let ride of rides">
                    <td class="table-cancel-width">
                      <button mat-stroked-button color="danger" (click)="cancelRide(ride)"
                        *ngIf="!statusTaximeterStarted(ride) 
                          && !statusUnknown(ride) 
                          && !statusInVain(ride)  
                          && !statusNoShow(ride)
                          && !statusCancelled(ride)
                          && !statusCompleted(ride)">
                        {{'cancelTaxi' | translate}}
                      </button>
                    </td>
                    <td>{{ ride.pickupTime + 'Z' | date:'dd/MM HH:mm' }}</td>
                    <td *ngIf="statusOrdered(ride)"
                        class="status">{{ 'shortTaxiStatusOrdered' | translate }}</td>
                    <td *ngIf="statusOnItsWay(ride)"
                          class="status">{{ 'shortTaxiStatusOnItsWay' | translate }}</td>
                    <td *ngIf="statusTaximeterStarted(ride)"
                          class="status">{{ 'shortTaxiStatusTaximeterStarted' | translate }}</td>
                    <td *ngIf="statusInVain(ride)"
                          class="status">{{ 'shortTaxiStatusInVain' | translate }}</td>
                    <td *ngIf="statusNoShow(ride)"
                          class="status">{{ 'shortTaxiStatusNoShow' | translate }}</td>
                    <td *ngIf="statusUnknown(ride)"
                          class="status">{{ 'shortTaxiStatusUnknown' | translate }}</td>  
                    <td *ngIf="statusArrived(ride)"
                          class="status">{{ 'shortTaxiStatusArrived' | translate }}</td>  
                    <td *ngIf="statusCancelled(ride)"
                          class="status">{{ 'shortTaxiStatusCancelled' | translate }}</td>  
                    <td *ngIf="statusCompleted(ride)"
                          class="status">{{ 'shortTaxiStatusCompleted' | translate }}</td>  
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="right-container">
      <div #mapContainer class="google-map">
        <app-google-map [carCoordinates]="taxiCars" [markerPosition]="hotelLocation" [height]="height" [width]="width"></app-google-map>
      </div>
      
      <!--
      <div style="width: 45vw; height: 85vh; background-color: greenyellow;">
      </div>
      -->
    </div>
  </div>
</ion-content>