import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { GetTaxiService } from '../../services/get-taxi.service';
import { ShareService } from '../../services/share.service';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController } from '@ionic/angular';
import { PageService } from 'src/app/services/page.service';
import { HotelService, PaymentOption } from 'src/app/services/hotel.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
    selector: 'app-bill-history',
    templateUrl: './bill-history.component.html',
    styleUrls: ['./bill-history.component.scss']
})
export class BillHistoryComponent implements OnInit {

    controlDate: Date;
    hotelId: any;
    accessToken: any;
    airportId: any;

    currency = '';
    currencySubscription?: Subscription;

    query: any;
    loading!: HTMLIonLoadingElement;

    // testing parems for use in website
    @Input() selectedRoom: any = undefined;
    isManual = false;

    showChild: boolean = false;

    constructor(private router: Router, public service: GetTaxiService, private parameterService: ParameterService, private alertController: AlertController,
        private translate: TranslateService, private shareService: ShareService, public loadingController: LoadingController, private pageService: PageService, private hotelService: HotelService) {
        let today = new Date();
        this.controlDate = new Date(today.getFullYear(), today.getMonth(), 2, 0, 0, 0, 0);
        this.parameterService.parameters.subscribe(async (params) => {
            if (params === undefined)
                return;

            this.hotelId = params.hotelId;
            this.accessToken = params.accessToken;
            this.airportId = params.airportId;

            if (this.hotelId === '3980af32-88b1-4796-bcaa-f840982b1ddb') {
                this.isManual = true;
            }
        });
    }

    reciepts: any;

    ngOnInit(): void {
        this.compareDateTime('2022-06-24T15:00:00');
        this.reload();
        this.currencySubscription = this.hotelService.currency.subscribe(currency => this.currency = currency);
    }

    ngOnDestroy(): void {
        this.currencySubscription?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.reload();
    }

    reload(): void {
        this.getReceipts(this.controlDate);
        this.pageService.currentHeaderTranslationKey = 'bills';
    }

    addDate() {
        this.controlDate = new Date(
            this.controlDate.getFullYear(),
            this.controlDate.getMonth() + 1,
            this.controlDate.getDate());
        this.getReceipts(this.controlDate);
    }

    prevDate() {
        this.controlDate = new Date(
            this.controlDate.getFullYear(),
            this.controlDate.getMonth() - 1,
            this.controlDate.getDate());
        this.getReceipts(this.controlDate);
    }

    goToBill(data: any) {
        /* this.router.navigateByUrl("/bill?hotelId=" + this.hotelId + "&accessToken=" + this.accessToken +
          "&airportId=" + this.airportId); */

        this.selectedRoom = data;
        this.showChild = true;

        /* setTimeout(() => {
        this.shareService.send(data);
      }, 1000);*/
    }

    async getReceipts(tripDate: Date) {
        let body = {
            hotelId: this.hotelId,
            accessToken: this.accessToken,
            date: this.controlDate.toISOString()
        };
        await this.presentLoading();
        this.service.getReceipts(body).subscribe(async (data) => {
            let notApplied = [];
            let Applied = [];
            for (let receipt of data) {
                if ((receipt.paymentOption & PaymentOption.Prepay) === PaymentOption.Prepay || (receipt.paymentOption & PaymentOption.HotelPays) === PaymentOption.HotelPays) {
                    receipt.prepaid = true;
                }

                if (receipt.applied != true) {
                    notApplied.push(receipt);
                } else {
                    Applied.push(receipt);
                }
                receipt.total = 0;
                for (let ride of receipt.rides) {
                    receipt.total += ride.totalPrice;
                    if (ride.sharedRideSavings) {
                        receipt.total -= ride.sharedRideSavings;
                    }
                }
            }
            this.reciepts = notApplied.concat(Applied);
            await this.hideLoading();
        }, async (error) => {
            await this.hideLoading();
            const alert = await this.alertController.create({
                header: 'Error Occurred',
                subHeader: this.translate.instant('500errorMsg'),
                cssClass: 'alertmessage',
                buttons: [
                    {
                        text: 'Okay',
                        handler: () => {
                        }
                    }
                ],
                backdropDismiss: false
            });
            await alert.present();
        });
    }

    public setActiveAndReload() {
        this.showChild = false;
        this.reload();
    }

    async presentLoading() {
        this.loading = await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: this.translate.instant('pleaseWait'),
        });
        await this.loading.present();
    }

    async hideLoading() {
        await this.loading.dismiss();
    }

    compareDateTime(date: string): boolean {
        const dateTime = new Date(date);
        const dateTimeNow = new Date(new Date().toUTCString());
        console.log(dateTime < dateTimeNow);

        if (dateTime > dateTimeNow) {
            return true;
        } else {
            return false;
        }
    }


}
