import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { GetTaxiService } from '../../services/get-taxi.service';
import { Subscription } from 'rxjs';
import { ReceiptService } from 'src/app/services/receipt.service';
import { HotelService, PaymentOption } from 'src/app/services/hotel.service';
import { ReceptionistInitialsService } from 'src/app/services/receptionist-initials.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss']
})
export class BillComponent implements OnInit, OnDestroy {

  @Input() isManual = false;
  hotelId: any;
  accessToken: any;
  airportId: any;

  currency = '';
  currencySubscription?: Subscription;
  currentDate = new Date();
  @Input() public room: any;

  @Output() backCliedEvent = new EventEmitter();

  total: number = 0;

  constructor(public service: GetTaxiService, private parameterService: ParameterService,
    private ref: ChangeDetectorRef, private receiptService: ReceiptService, private hotelService: HotelService, private receptionistInitialsService: ReceptionistInitialsService) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.accessToken = params.accessToken;
      this.airportId = params.airportId;
    });
  }

  loadFromRoom(room: any) {
    console.log(room);
    this.room = room;
    this.ref.detectChanges();
    this.total = 0
    for (let ride of this.room.rides) {
      if ((ride.paymentOption & PaymentOption.Prepay) === PaymentOption.Prepay) {
        ride.prepaid = true;
      }

      this.total += ride.totalPrice
      if (ride.sharedRideSavings)
        this.total -= ride.sharedRideSavings
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.room) {
      this.loadFromRoom(this.room);
    }
  }

  ngOnInit(): void {
    /* if (this.fromParent)
      this.loadFromRoom(this.room)
    else {
      this.shareService.subj$.subscribe(data => {
        this.loadFromRoom(data);
      });
    }*/

    // this.pageService.currentHeaderTranslationKey = 'bills';
    this.currencySubscription = this.hotelService.currency.subscribe(currency => this.currency = currency);
  }

  ngOnDestroy(): void {
    this.currencySubscription?.unsubscribe();
  }

  goToBillHistory() {
    this.backCliedEvent.emit();
  }

  canApply() {
    return !this.receptionistInitialsService.isInitialsRequired() || this.receptionistInitialsService.isInitialsSet();
  }

  applyRides() {
    let rideIds = [];
    for (let ride of this.room.rides) {
      rideIds.push(ride.id)
    }
    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      rideIds: rideIds,
      receptionistInitials: this.receptionistInitialsService.getInitials()
    }
    this.service.applyRide(body).subscribe(data => {
      this.room.applied = true;
      this.receiptService.update();
    })
  }

  applyRide(ride: any) {
    let rideIds = [];
    rideIds.push(ride.id)
    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      rideIds: rideIds,
      receptionistInitials: this.receptionistInitialsService.getInitials()
    }
    this.service.applyRide(body).subscribe(data => {
      ride.applied = true;
      this.receiptService.update();
    })
  }
  deleteRide(ride: any) {
    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      rideId: ride.id
    }
    this.service.deleteRide(body).subscribe(data => {
      this.room.rides = this.room.rides.filter((value: any) => { return value.id != ride.id });
      this.total = 0
      for (let ride of this.room.rides) {
        this.total += ride.customerPrice
        if (ride.sharedRideSavings)
          this.total -= ride.sharedRideSavings
      }
    })
  }

}
