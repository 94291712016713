<div class="container">
    <mat-dialog-content class="content">
        <h1>{{ "ridesWhereCancelled" | translate }}</h1>
        <div class="ride" *ngFor="let ride of rides">
            <div *ngIf="ride.guestName" class="title">{{ 'guestName' | translate }}: {{ ride.guestName }}</div>
            <div *ngIf="!ride.guestName" class="title">{{ 'tel' | translate }}: {{ ride.phoneNumber }}</div>
            <div *ngIf="statusInVain(ride)" class="title">{{ 'reason' | translate }} {{ 'taxiStatusInVain' | translate }}</div>
            <div *ngIf="statusNoShow(ride)" class="title">{{ 'reason' | translate }} {{ 'taxiStatusNoShow' | translate }}</div>
            <div *ngIf="statusUnknown(ride)" class="title">{{ 'reason' | translate }} {{ 'shortTaxiStatusUnknown' | translate }}</div>
            <div *ngIf="statusCancelled(ride)" class="title">{{ 'reason' | translate }} {{ 'taxiStatusCancelled' | translate }}</div>
            <ul class="info">
                <li *ngIf="ride.phoneNumber">{{ 'tel' | translate }}: {{ ride.phoneNumber }}</li>
                <li>{{ 'guestPickedUp' | translate }}: {{ ride.pickupTime + 'z' | date:'hh:mm-dd/MM' }}</li>
                <li>{{ 'from' | translate }}: {{ ride.from }}</li>
                <li *ngIf="ride.to">{{ 'to' | translate }}: {{ ride.to }}</li>
                <li *ngIf="ride.roomNumber">{{ 'roomNo' | translate }}: {{ ride.roomNumber }}</li>
                <li *ngIf="ride.flightNumber">{{ 'flightno' | translate }}: {{ ride.flightNumber }}</li>
            </ul>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="action-buttons">
            <button class="button yes" (click)="close()">
                {{ "Okay" | translate }}
            </button>
        </div>
    </mat-dialog-actions>
</div>
