// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: "https://api.app-test.commutetaxi.com/api",
  getTaxiToHotel: "/GetTaxiToHotel?code=OpTE9Zym7p2avVhQbHc/TYW4vwGGFyHW3NLkcljXaEOmI6tp8fG72w==",
  getTaxiToAirport: "/GetTaxiToAirport?code=iSwR1C4eNAs1yOa8oYAu9SqVwoxRDf4J/tQpqRI8pClmRuXYrYkuCQ==",
  getOtherDestinations: "/GetOtherDestinations?code=5iTXJnb1pQOYa95cvYX7Vn1b7VIDNmV8TVjRy3UlnEPcPisqg0UK/A==",
  getRides: "/GetRides?code=91srKIE70fZzsSVugGAz9aPRjXrs0v87Ivwb8XKp0maPQxNahprwQA==",
  getCompletedTaxiCount: "/GetCompletedTaxiCount?code=n7z6OmBuFNaQaqFZvq4dtnNYfUhE5DN9WQfLYDrsWQio2AwKbdadXg==",
  getRideInfo: "/GetRideInfo?code=wXUCF/mMTVEtR9Bl8pMEd5r8Xdv/K3pBcbNUAnWe7M6yCs92aA4w/w==",
  getTaxiPrice: "/GetTaxiPrice?code=ZMn9L8qLcoLAjWj5aNJCEfkUbqAaJUtH0oLaW1JLYBI6uuGwgJEyYQ==",
  getAirportPrice: "/GetAirportPrice?code=EJEVIj7X9JJY2EwoX6EcaKrCn5USreiFEptEnCwtyvY3qzg0hxaiOA==",
  cancelRide: "/CancelRide?code=1JvSeezkS7CAFjes4RhibaEihrSYFAweJkkCW9dknVlHSjqM8Bj/OQ==",
  getRecipts: "/GetReceipts?code=TQv8SI6x0cW8aiVMSE0EbLbIY9pfgGkfO0Dsa5EZU3KtRZjZdEIGaA==",
  applyRide: "/ApplyRide?code=YioCwOifcnqiVK4UR90k1fWa5kGgWm8Ll9iW3dbk4iJWvteq5XOM4g==",
  deleteRide: "/DeleteRide?code=2icaj4ClI9LCr899z5maz4eawAihcOX064zwZ89Yo1a2NgxRZQsn9g==",
  directTaxiRide: "/DirectTaxiRide?code=LvOO1BR8kUkmCWBd6ykQxXlhsR0cSSB/fwiIL13bOBaNcxPouF9oAA==",
  saveOtherDestinations: "/SaveOtherDestinations?code=7JykmvaIkoF4HDlct3n1emw79xCr8nClIKN6hwvPHeeKtlALTNavNg==",
  saveTaxiToAirport: "/SaveTaxiToAirport?code=iCt0KUyRKI4Mn/UneKsTGfZUWdF58X4n0OYpUsb56NFuQndsxY9HWg==",
  saveTaxiToHotel: "/SaveTaxiToHotel?code=K31OC9HEj5j6717ojCnd5kDoKR/qCgkQ2GlT1lpLYr/wY6zw1l7djw==",
  getFixedDestinations: "/GetFixedDestinations?code=qiD8spth9cdQmp5ca89mGZjNPUnJPaRBofO2RaindKNwLs3SuUxfog==",
  getFixedDestinationsExcludingAirports: "/getFixedDestinationsExcludingAirports?code=8YFsxI7OT/ZtUWyXfSN6OHBHHesfHToK5L7UzOu/3RVOw8YfubvWSg==",
  getFixedDestinationsByIds: "/GetFixedDestinationsByIds?code=gPzXVaVTm2RBM5MHWdIJUCWPCwqVjOiRKe5ImGxIiTu7OhQgJ6hrWA==",
  updateFixedDestinations: "/UpdateFixedDestinations?code=SDgX6jVkmEDL70vzbalvKWaITNFblOX/qWMKH821bjETa0Ktz5tL3Q==",
  createFixedDestinations: "/CreateFixedDestinations?code=VfCBau/6vO1/WayBJQXt6UNacywFagZa2sdMysMl43Ljq9XJOHa2Ng==",
  deleteFixedDestinations: "/DeleteFixedDestinations?code=NYfvWc6ArF7kPuQTWSE8VqyZ3ao4zlQVzv1agIbnL4V/qtTNHY2kCg==",
  verifyAdmin: "/VerifyAdmin?code=MZHYPDqWMme9yqowKh5fzgE02f/tRxrMf6lPkuRdnMVS8lxn5DrgvA==",
  getAirportsForHotel: "/GetAirportsForHotel?code=o79E99xmgQqasDoCWalUaZE0RBfrpc7bZvMQurBFLVysi8wxtJRFjQ==",
  getAirportInfo: "/GetAirportInfo?code=ma56iZPYO3m1uStWQIv5qUyAFTMkAmqHn1Ra1lAPuglE0xvCIn4gBA==",
  getHotelConfiguration: "/GetHotelConfiguration?code=b6ynULKwArSVNB8Fp1wOwcGZ25DTkR81fqMCFi1KtcdF6X4lgbCfqA==",
  GetSopBookingAdditionalInfo: "/GetSopBookingAdditionalInfo?code=jaeJgB3Sw1BaBacL5e6EP4HVNBxv9Fbo1HmYYe2mXbwuAzFuUhIicA==",
  getTaxiConfiguration: "/GetTaxiConfiguration?code=tol/4dFdJKZoAIfaRrHKLZR54JrsUIy46j486/zz7Uis9u8qHn17GQ==",
  getTaxiConfigurationByRide: "/GetTaxiConfigurationByRide?code=2GZDVOblA3wOGJoJzybp4ZY8sXpfC4wf7DA57/aUKdZ3P9YETHs88w==",
  getFlightInfo: "/GetFlightInfo?code=iEFmuSyT9ZamLQt7eoj2MZeL/FG38Jpp5HfAWbuGbdAGhDRGQ/PQDg==",
  getArrivalsOfTheDay: "/GetArrivalsOfTheDay?code=tPA4kZjVfok3WOJo9EhMOrjv5ShL5VbIju9J7rQCOYOFNuP/GUT37w==",
  getUpcomingRides: "/GetUpcomingRides?code=2sngXwyMJ420t9rHEY5wwUaBbOnV96gTIYuKj4Mowj0phHBTqR/MRw==",
  getHotelInfo: "/hotel/info/{hotelId}?code=KrX_IxeAqg9513g8Olz0UDdqNiIDEMvnKXEcgIF1KZklAzFuJc_qfA==",
  selfServiceStationGetPaymentToken: "/payment/token/{hotelId}?code=CzR97EOH8qTTL0-tM3qHYhMLluJUZ0whbBPDAKaSpXQRAzFurvLuVQ==",
  selfServiceStationCreateIntent: "/payment/intent?code=wqExvEc6bN-SGFq3_PtDkit_P0bGp6jCF3YaI7EMWE0qAzFuoGjaIw==",
  selfServiceStationMobileCreateCheckout: "/payment/checkout?code=8Ro5qFHTXFJkBLe7jFYCjblSa6SKZsLCVjuDvkeYFb-PAzFuW_mFrQ==",
  selfServiceStationMobileGetCheckoutData: "/payment/checkoutData/{checkoutId}?code=IVrsWxbUPUu5BiksHs1KNbvfg1e3dnfED-X3YAh4WL4UAzFuJVmDeQ==",
  getHotelInformationForAdminUrlRedirector: "/GetHotelInformationForAdminUrlRedirector?code=scH4vxA1j15q-pXDZAp_7B2n4hN8BPWcY-u0kZsYjd2YAzFudCo4dQ==",
  log: "/Log?code=T_2odN1pJlw4V8uLwH5mIbu6mlXVWppNy48tF-cY6jfbAzFuOmBAng==",
  getPaymentServiceVariant: "/payment/variant/{hotelId}?code=i2iCqmTDa9sE9YgjjQET7dawF7WKJsvzseJuL4ushQB3AzFuBLAnSQ==",
  getPaymentIntentId: "/payment/intentid?code=wY4og-0XvgiqfahAQszViGRlPmMel2cpN7KXT-5-3uOAAzFuGjBlFw==",
  getAllHotelDataJson: "/GetAllHotelDataJson?code=Mh8z4scHChSJK50Oo3W_XpLjaK5fsQg8kQqmJPUSN21LAzFunLyY1A==",
  setHotelDataFromJson: "/SetHotelDataFromJson?code=ABWlrHGWYnTnyQ2Twv03zSlcWl-mgHtblWZYA7rBle1TAzFu0r1czA==",
  getPrefixedComment: "/GetPrefixedComment?code=Xt9e52dVprxhEiqlc0vy4NNeQpyPQMKS5s8-YEX4RMPgAzFuT2E6YA==",
  getHotelCountry: "/hotel/getCountry/{hotelId}?code=BeuRSADjQfP0yxv5uZO_8RydOOUGhfr7tunqFtfWWQ1bAzFu24XZcQ==",
  getRideCountry: "/ride/getCountry/{rideId}?code=btcq0PgyCfA0uEbOde-gmpsGw98T4ERp1D3JkgTkTv-IAzFuZrQQ7Q==",
  getPrepayLink: "/payment/prepayLink?code=yx9qdCHiUTPnvYwgXyzJfTcBBAVzEFi8tnRpiJQ6hqMgAzFuv_AQ9g==",
  getCustomer: "/GetCustomerFromHotel?code=eMXML9oyVu5kaFVrso-XPWyxngpGozfk2lH3Ccue1KQeAzFuOmfFNQ==",
  getCancelledRidesForApplyToBill: "/getCancelledRides/{hotelId}?code=NQFvpZyhd10iApvT9-yNwlzbYgUCfg543wYaKAyycd26AzFuK1R06w==",
  getSpecialHotelInformation: "/GetSpecialInformation?code=UWdtuFzaOY3yJohUif2k5oR1w7Ca6y7Jaj43ze9xtxNMAzFur6j1NA==",
  getCancelledRidesByDriver: "/GetCancelledRidesByDriver?code=VF--NzsXPkALguu1UOblq0sMDFRkjuUfiSgcTOdYAJoUAzFupUjrLw==",
  getLargeTaxiStatus: "/GetLargeTaxiStatus?code=8SyN42B3_udc3hTI80-H7PvgcPceaLmfVmecBjdyWP_4AzFuk_alMw==",
  getAvergeTime: "/GetArrivalTimeAverage?code=-42WIm0aevKOABHC7NxgZWpqo5I6ursDtM-rSBLG2e4JAzFuS1xo0A==",
  getCO2Pdf: "/GetCO2PDF?code=aP8wOn1vHanq07k13EAOojTUwuaOqV3mB0G3_1yu580lAzFu9rCzqg==",
  getCO2Products: "/GetCO2Products?code=SjQ_i8iWsSZJStwEogj6zYMmZDlfgTRagstGkr2Rr8-1AzFug05_tA==",
  co2ProductBought: "/CO2ProductBought?code=RI5cfvWF9oNy4Bbf7GOqAmXvWJHB0-_l4nS7GlE46uA1AzFuh4LgWw==",
  getGetMonthsWithRides: "/GetMonthsWithRides/{hotelId}?code=1rtfEKEzoPI1Jekgj6GYoCmt1oN29KmmH7pycP8ZCRTwAzFuY7pV5w==",
  getRatingForHotel: "/GetRatingForHotel?code=sHBpSamL2iqI8EJKqgyy86nCQAYNArPErz9Hu5WVqtY9AzFuneAHSA==",
  updateRidesReceptionComment: "/UpdateRidesReceptionComment?code=U3ZRY7GQsZ_2nghJb5KPXxUKlvmFae4fuzl02hp4pr6YAzFuDv0mvg==",
  getAddressByCords: "/GetAddressByCords?code=LWCc3q1HNo9lzfHNRKk2qZTgcV2rc8aaohW54XanoJF7AzFuCMCC0g==",
  sendReceiptEmail: "/SendReceiptEmail?code=_Ng0M-avpfO6UUU71Xj8ExPXV21V7d1ubU70lLPfNCBqAzFu5Yawag==",
  getRestaurantRides: "/GetRestaurantRides?code=JifGUlSe8wYmRz33XY7HuvssGNHgIR5vxM2QdRevpCF9AzFuk2hOcg==",
  getHotelSettings: "/GetHotelSettings?code=CvpybCzRxabvjTj-11-ijrypRJK3-_yW_yOn4PVBrNvmAzFu8e1jvA==",
  updateHotelSettings: "/UpdateHotelSettings?code=8rJaJpxHfdHmagZ1_ZJ1g3tlVlUt2sMherRuBGTYWJLqAzFuvB4X8g==",
  updateRide: "/UpdateRide?code=GQdi4eysZ1KHjzhxg1piNYjFApdQ7YY-VCimEraHMU0pAzFuWcac9A==",
  getRideToUpdate: "/GetRideToUpdate?code=vhCPHIT81s1hKR875ZsIUzF5Y_-cJrJKXCj3RX5_upquAzFuVihvfA==",
  getRideStatus: "/GetRideStatus?code=TPFZGn8i19lkexeL_bgOElFhnOfN-_dIvBVdBkWl6DszAzFuRrVDwQ==",

  selfServiceStation_ResetInterfaceTimeoutMs: 180000,
  selfServiceStation_ShowResetPopUpTimeoutMs: 120000,
  selfServiceStation_IsTerminalSimulated: false,
  nmiTerminalCheckForAsyncStatusUpdateIntervalMs: 2000,
  GetTaxiLocation: "/GetRideInTheWay?code=bOndQL734nLfKQOpFeREZqpoFhOQieLqxa9l2o0jxhKBAzFuOH3rlQ==",
  saveOtherDestinationTerminal: "/SaveOtherDestination/terminal?code=hPdhta-s6RcF3VjOUpX82b6s7BegAhrp4Du7G0Bo3N9ZAzFu9ZMTqw==",
  selfServiceStation_ResetInterfaceIfLoadingFailureTimeoutMs: 10000,
  getTaxiLocationByTrackingCode: "/Ride/taxiLocation/{trackingCode}?code=iQq59WR-O4tUPO5rn36ebceu0NLLY9j6M7yyIo_vu4YIAzFuBmEjhg==",
  getReciptPDF: "/receiptpdf/{rideId}?code=oHEiRa5pz7dHr4FLSWZdOrklzMVgecYwnG4iKmPEbnOZAzFuWi6f4A==",
  getModelPDF: "/receiptmodelpdf/{rideId}?code=zHG-_RJo6r4loaXozYbnfKrey7jz_ID9q56tLmn8YYBYAzFuoTzkSA==",
  showReceiptUrl: "/receipt?rideId={rideId}",
  checkForNewVersionUpdateDelayMs: 60000,
  receptionistPlatformPayInTaxiOpenDialogDelayMs: 10000,
  sendQRCodeLink: "/SendQRLink?code=ayPn7FJzTvKblyK9aJw3f807A2hfu1k4-PSOqLNGpXynAzFuKOWeXw==",
  getRidePaymentInfo: "/GetRidePaymentInfo?code=XdqmRwozs-4zIqctZq9r_xhSJSWf1PUPMh0iydTQLmwLAzFuIy2BFQ==",
  confirmPaymentAndOrderRide: "/ConfirmPaymentAndOrderRide?code=wNXqBSuesrrikFISMYtuyFnXoQshzjPpt-_unjw9xQURAzFuADtkYw==",
  extendOrderTimeWhenClickingOnLink: "/ExtendOrderTimeWhenClickingOnLink?code=Kr_AJdhFlbLuV5yyXWERBSdS2yHfT3cD8kUQz2fC4lWEAzFuNJN2ew==",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
