<form [formGroup]="secondDestination"
      (submit)="submit()"
      #secondDestinationForm="ngForm"
      *ngIf="messageShow==false">
  <div class="list distance">
    <mat-radio-group color="primary"
                     aria-label="Select an option"
                     formControlName="destination">
      <mat-radio-button *ngFor="let destination of otherDestinations"
                        [value]="destination">
        <div class="row">
          <div class="box">
            <h4>{{ destination.name }}</h4>
            <!-- <span class="subtitle">{{ destination.address }}</span> -->
          </div>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <app-destination-selector *ngIf="!toHotelActive"
                            formControlName="destination"
                            [fromHotel]="toHotelActive"
                            [showErrors]="formSubmit"></app-destination-selector>

  <app-destination-selector *ngIf="toHotelActive"
                            formControlName="from"
                            [fromHotel]="toHotelActive"
                            [showErrors]="formSubmit"></app-destination-selector>


  <div class="pick-time-and-price">
    <div>
      <ion-label class="bold"> {{ "guestPickedUp" | translate }} </ion-label>
      <div>
        <app-date-time-picker formControlName="pickupTime"
                              [autoIncrementTime]="true"
                              (change)="onChangeDate()"></app-date-time-picker>
      </div>
    </div>

    <div *ngIf="isManual">
      {{ "guestPrice" | translate }}
      <div>
        <input type="number"
               min="0"
               class="form-controls ion-text-right"
               formControlName="taxiPrice"
               (keyup)="onChangePrice()"
               (change)="onChangePrice()" />
      </div>
    </div>
  </div>
  <div class="forms">
    <div class="float-container">
      <div class="form-group roomNo">
        <ion-label class="bold">{{ "roomNo" | translate }}</ion-label>
        <ion-input type="text"
                   placeholder="“253“"
                   class="form-controls input-placeholder"
                   formControlName="roomNo"></ion-input>

      </div>

      <div *ngIf="getCustomer">
        <button type="button" id="roomNumberButton" (click)="roomNumberGetInfo()" class="roomNumberButton">✓</button>
      </div>
      
      <div *ngIf="hideNameAndPhone" class="applyToBill-News">
        {{ "applyToBillNews" | translate }}
      </div>

      <div *ngIf="hideNameAndPhone==false" class="form-group" [ngClass]="{'m-l-10': !getCustomer}">
        <ion-label class="bold">{{ "guestName" | translate }}</ion-label>
        <ion-input formControlName="guestName"
                   type="text"
                   [placeholder]="translate.instant('guest_name_placeholder')"
                   class="form-controls" input-placeholder></ion-input>
      </div>

      <div *ngIf="hideNameAndPhone==false">
        <ion-label class="bold">{{ "phoneNoLabel" | translate }}</ion-label>
        <app-phone-number-input-pc 
          type="button"
          class="phone-number"
          (onPressNumber)="phoneSelect($event)"
          (click)="scrollToBottom()"
          [hotelId]="hotelId"
          [phoneNumber]="phoneNumber">
        </app-phone-number-input-pc>
      </div>

    </div>

    <div *ngIf="guestName && formSubmit"
         class="alert alert-danger strange-margin">
      <div *ngIf="guestName?.errors?.roomNoOrGuestNameRequired">
        {{ "roomNoOrGuestNameRequired" | translate }}
      </div>
      <div *ngIf="roomNo?.errors">
        {{ "roomNumberMissing" | translate }}
      </div>
    </div>

    <app-apply-to-bill-box *ngIf="showApplyToBill"
        formControlName="paymentOption"
        [showErrors]="formSubmit"
        [disabled]="noDestinationSelected"
        class="apply-to-bill-box"></app-apply-to-bill-box>
  </div>

  <ng-container *ngIf="disableOptions()==false">
    <ng-container *ngIf="isManual==false">
      <ion-label class="bold">{{ "possibilityToOrder" | translate }}</ion-label>
    </ng-container>
    <div class="taxiInfo"
         *ngIf="isManual==false">
      <div class="options-table">
        <mat-checkbox aria-label="Select an option"
                      formControlName="largeTaxi"
                      color="primary"
                      (change)="isShowShare($event)"
                      [disabled]="canOrderLargeTaxi==false"
                      *ngIf="displayOption('large')">
          <span class="mat-checkbox-label">
            {{ largeTaxiTranslation }}
          </span>
        </mat-checkbox>

        <mat-checkbox aria-label="Select an option"
                      (change)="isShowShare($event)"
                      formControlName="stationCar"
                      color="primary"
                      *ngIf="displayOption('estate car')">{{ "stationCar" | translate }}
        </mat-checkbox>

        <mat-checkbox aria-label="Select an option"
                      (change)="isShowShare($event)"
                      formControlName="electricCar"
                      color="primary"
                      *ngIf="displayOption('electric car')">{{ "electricCarLabel" | translate }}
        </mat-checkbox>

        <mat-checkbox aria-label="Select an option"
                      (change)="isShowShare($event)"
                      formControlName="animal"
                      color="primary"
                      *ngIf="displayOption('bring pet')">{{ "animalLabel" | translate }}
        </mat-checkbox>

        <mat-checkbox aria-label="Select an option"
                      (change)="isShowShare($event)"
                      formControlName="bike"
                      color="primary"
                      *ngIf="displayOption('bring bike')">{{ "bikeLabel" | translate }}
        </mat-checkbox>

        <mat-checkbox aria-label="Select an option"
                (change)="isShowShare($event)"
                formControlName="carseat"
                color="primary"
                *ngIf="displayOption('carseat')">{{ "carseat" | translate }}
        </mat-checkbox>

        <mat-checkbox aria-label="Select an option"
                (change)="isShowShare($event)"
                formControlName="oneBoosterSeat"
                color="primary"
                *ngIf="displayOption('oneBoosterSeat')">{{ "oneBoosterSeat" | translate }}
        </mat-checkbox>

        <mat-checkbox aria-label="Select an option"
                (change)="isShowShare($event)"
                formControlName="twoBoosterSeats"
                color="primary"
                *ngIf="displayOption('twoBoosterSeats')">{{ "twoBoosterSeats" | translate }}
        </mat-checkbox>

        <mat-checkbox aria-label="Select an option"
                (change)="isShowShare($event)"
                formControlName="childSeat"
                color="primary"
                *ngIf="displayOption('childSeat')">{{ "childSeat" | translate }}
        </mat-checkbox>

        <mat-checkbox aria-label="Select an option"
                (change)="isShowShare($event)"
                formControlName="hybridCar"
                color="primary"
                *ngIf="displayOption('hybridCar')">{{ "hybridCar" | translate }}
        </mat-checkbox>

        <app-hotel-payment *ngIf="displayOption('hotel pays')"
                           (hotelPayment)="hotelPaymentChanged($event)"></app-hotel-payment>

        <div class="number"
             *ngIf="displayOption('number of cars')">
          <ion-label>{{ "numberOfCarsLabel" | translate }}</ion-label>
          <ion-input max="25"
                     min="1"
                     type="number"
                     formControlName="numberOfCars"
                     class="form-control with-outline"
                     [ngClass]="{'alert alert-danger': numberOfCars?.errors?.required || numberOfCars?.errors?.min || numberOfCars?.errors?.max}">
          </ion-input>
        </div>

        <div class="large"
             *ngIf="displayOption('comment')">
          <ion-label>{{ "commentToDriverLabel" | translate }}</ion-label>
          <ion-input formControlName="commentToDriver"
                     maxlength="200"
                     type="text"
                     placeholder="e.g. “”"
                     class="form-control with-outline"></ion-input>
        </div>
      </div>

      <div *ngIf="largeTaxi && formSubmit"
           class="alert alert-danger">
        <div *ngIf="largeTaxi?.errors?.required">
          {{ "pleaseSelectOption" | translate }}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="taxiInfo"
       *ngIf="showOnLarge && canOrderLargeTaxi">
    <h2>
      <mat-icon>person</mat-icon> {{ "chooseNumberOfGuests" | translate }}
    </h2>
    <div class="innercontainer">
      <div class="guests">
        <span class="steps">5</span>
        <span class="steps">6</span>
        <span class="steps">7</span>
        <span class="steps">8</span>
      </div>
      <mat-slider class="customSlide"
                  thumb
                  tickInterval="1"
                  min="5"
                  max="8"
                  formControlName="numberOfGuests"
                  [value]="1"></mat-slider>
    </div>
  </div>

  <app-payment-option *ngIf="!isManual==false || !hotelSystemIntegrationApplyToBill==true"
                      formControlName="paymentOption"
                      [paymentOptionFilterMethod]="paymentOptionFilter"
                      [showErrors]="formSubmit"
                      [isLargeTaxi]="largeTaxi?.value"></app-payment-option>

  <app-order-button [isManual]="isManual"
                    [paymentOption]="paymentOption?.value"></app-order-button>
</form>

<div class="taxi-ordered thankyou" *ngIf="messageShow">
  <div *ngIf="showLargeTaxiLoading">
    <div *ngIf="loadingDone==false">
      <h3>{{ "processingLargeTaxiOrder" | translate }}</h3>
      <mat-progress-bar [mode]="'determinate'" [value]="progressValue" class="progress-bar">
      </mat-progress-bar>
    </div>
    <div *ngIf="loadingDone">
      <h3>{{ largeTaxiCarResponse }}</h3>
      <h3 *ngIf="showEmote" class="emote">{{ "smileyEmoji" | translate }}</h3>
    </div>
  </div>
  <div *ngIf="showLargeTaxiLoading==false">
    <h3>{{ "thankYouForYourOrderShort" | translate }}</h3>
    <h3 *ngIf="showEmote" class="emote">{{ "smileyEmoji" | translate }}</h3>
  </div>
</div>