import { IHotelInfo } from '../models/hotel-info.interface';
import { IDestination } from '../shared/models/destination.interface';

export class HotelResponseMapperService {
    public static mapHotelInfoToIHotelInfo(data: any): IHotelInfo {

        let lang = "en";

        switch (data.country) {
            case "Denmark":
                lang = "da"
                break;
            case "Norway":
                lang = "no"
                break;
            case "Germany":
                lang = "de"
                break;
            case "Sweden":
                lang = "se"
                break;
            case "Finland":
                lang = "fi"
                break;
        }

        var location: IDestination[] | undefined = undefined;

        if (data.hotelSpecialLocations) {
            location = [];
            data.hotelSpecialLocations.forEach((element: { name: any; address: any; }) => {
                location!.push({ name: element.name, address: element.address });
            });
        }

        return {
            id: data.id,
            name: data.name,
            address: data.address,
            hotelLogoSrc: data?.hotelLogoSrc,
            logoSrc: data?.logoSrc ?? 'assets/images/logo_white.svg',
            terminalLocationId: data.terminalLocationId,
            terminalReaderId: data.terminalReaderId,
            zipCode: data.addressZipCode,
            lockedMobileRoutes: data.lockedMobileRoutes,
            hasNewOrderingFlow: data.hasNewOrderingFlow,
            currency: data.currency,
            language: lang,
            hasHotelSystemIntegration: data.hasHotelSystemIntegration,
            integrationOptions: data.integrationOptions,
            displayNewsPopup: data.displayNewsPopup,
            noDestinationSettings: data.noDestinationSettings,
            selfServicePayInTaxiAllowed: data.selfServicePayInTaxiAllowed,
            hotelSpecialLocations: location,
            country: data.country,
            establishmentType: data.establishmentType,
            longtitude: data.longitude,
            latitude: data.latitude
        };
    }
}
