import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-coin-tree-counter',
    templateUrl: './coin-tree-counter.component.html',
    styleUrls: ['./coin-tree-counter.component.scss']
})
export class CoinTreeCounterComponent implements OnInit {
    @Output() onLoadEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Input() completedTaxiCountTotal: number = 0;
    @Input() completedTaxiCountMonth: number = 0;
    @Input() monthlyRidesGoal: number = 0;
    @Input() displayCoinCounter: boolean = false;
    @Input() displayBasicCounter: boolean = false;
    @Input() displayTreeCounter: boolean = false;
    @Input() displayCoinProgressBar: boolean = false;
    @Input() displayPopupOnPrepaidCompleted: boolean = false;
    @Input() currency: string = "";
    @Input() earnedCoinsPerRide: number = 0;
    @Input() alreadyGotPaidAmount: number = 0;
    @Input() earnedCoinsDescription: string = "";
    @Input() prepaidTourCompletedDescription: string = "";
    @Input() prepaidTourCompleted: boolean = false;
    @Input() monthlyBonusGoalTitle: string = "";

    prepaidTourCompletedShow: boolean = false;
    popupTimer: number = 10000;
    isWhiteTextColor: boolean = false;

    constructor(private router: Router) {
        this.router.events.subscribe(() => {
            const currentRoute = this.router.url;
            // Check if the current route contains the specific parent route
            this.isWhiteTextColor = currentRoute.includes('restaurant') || currentRoute.includes('resturant') || currentRoute.includes('fast-order');
        });
    }

    ngOnInit(): void {
        this.onLoadEmitter.emit();
        document.addEventListener('mousemove', this.onMouseMove)
    }

    onMouseMove: { (event: MouseEvent): void } = (event: MouseEvent) => {
        if (this.prepaidTourCompleted) {
            this.prepaidTourCompletedShow = true;

            setTimeout(() => {
                this.prepaidTourCompleted = false;
                this.prepaidTourCompletedShow = false;
            }, this.popupTimer);
        }
    }

    getCoinCount(): number {
        return (this.completedTaxiCountTotal % 500) / 5;
    }

    getCoinProgressBarCount(): number {
        return Math.floor((this.completedTaxiCountTotal % 500) / 5);
    }

    getGiftCount(): number {
        return Math.floor(this.completedTaxiCountTotal / 500);
    }

    getEarnedCoins(): number {
        var result = this.completedTaxiCountTotal * this.earnedCoinsPerRide;
        result -= this.alreadyGotPaidAmount;
        return result;
    }

    getEarnedCoinsMonthly(): number {
        return this.completedTaxiCountMonth * this.earnedCoinsPerRide;
    }

    getProgressPercentage(): number {
        return (this.getEarnedCoinsMonthly() / this.monthlyRidesGoal) * 100;
    }

    getTreeProgressBarCount(): number {
        return Math.floor((this.completedTaxiCountTotal % 175) / 1.75);
    }

    getTreeCount(): number {
        return Math.floor(this.completedTaxiCountTotal / 175);
    }
}
