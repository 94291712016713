import {
    Component,
    OnInit,
    ElementRef,
    ViewChild,
    Renderer2,
    Output,
    EventEmitter,
    HostListener,
    Input,
    SimpleChanges
} from '@angular/core';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { country } from '../phone-number-input/codes';

interface IFlags {
    code: string;
    dial_code: string;
    name: string;
    img?: string;
}

@Component({
    selector: 'app-phone-number-input-pc',
    templateUrl: './phone-number-input-pc.component.html',
    styleUrls: ['./phone-number-input-pc.component.scss']
})
export class PhoneNumberInputPcComponent implements OnInit {
    flags: IFlags[] = [];
    countryDetails: IFlags[] = country;
    selectedFlag: IFlags | undefined;
    inputValue: number | undefined; // Property to hold the number
    phonePattern: RegExp = /^[0-9+]+$/;
    numberPattern: RegExp = /[^0-9]+/g;

    @Output('onPressNumber') onPressNumber: EventEmitter<any> = new EventEmitter();

    @Input() hotelId!: string;
    @Input() phoneNumber: any = { phoneNumber: "", countryCode: "" };

    @ViewChild('flagDropdown', { read: ElementRef }) flagDropdown: ElementRef | undefined;

    @HostListener('window:click', ['$event'])
    onClick(event: any): void {
        const className = event.target.className;
        if (!className.includes('dropbtn')) {
            if (!event.target.className.includes('search-form-control')) {
                this.render.removeClass(this.flagDropdown?.nativeElement, 'show');
            }
        }
    }

    constructor(private render: Renderer2, private getTaxiService: GetTaxiService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.phoneNumber && changes.phoneNumber.currentValue != "") {
            var phoneNo = changes.phoneNumber.currentValue.phoneNumber;
            var dialcode = "+" + this.phoneNumber.countryCode;

            //Find the flag
            if (this.flags.length == 0)
                this.formatFlags();

            var flag = this.flags.find(f => f.dial_code == dialcode);

            if (flag) {
                this.inputValue = phoneNo;
                this.selectedFlag = flag;
                this.onNumberPress(phoneNo);
            }
            else {
                this.onNumberPress(phoneNo);
            }
        }
    }

    ngOnInit(): void {
        this.formatFlags();
        if (!this.selectedFlag) {
            this.selectedFlag = this.flags.find(f => f.name === "Denmark");
            this.getTaxiService.getHotelCountry(this.hotelId).subscribe(data => {
                this.selectedFlag = this.flags.find(f => f.name === data.toString());
            });
        }
    }

    onDropDownClick(): void {
        if (this.flagDropdown?.nativeElement.classList.contains('show')) {
            this.render.removeClass(this.flagDropdown?.nativeElement, 'show');
        } else {
            this.formatFlags();
            this.render.addClass(this.flagDropdown?.nativeElement, 'show');
        }
    }

    onFlagSelect(f: any): void {
        this.selectedFlag = f;
        this.render.removeClass(this.flagDropdown?.nativeElement, 'show');
    }

    onNumberPress(v: string): void {
        this.onPressNumber.emit({
            valid: true,
            tel: v.length > 0 ? this.selectedFlag?.dial_code + v : v,
            data: this.selectedFlag
        });
    }

    search(v: any): void {
        this.formatFlags(v);
    }

    private formatFlags(v?: string): void {
        if (this.flags.length != 0 && !v)
            return;

        this.flags = this.countryDetails
            .filter(f => {
                if (v) {
                    const s = v.trim().toLowerCase();
                    return (
                        f.code.toLowerCase().includes(s) ||
                        f.dial_code.includes(s) ||
                        f.name.toLowerCase().includes(s)
                    );
                }
                return f;
            })
            .map(m => {
                m.img = m.code.toLowerCase() + '.png';
                return m;
            });
    }
}
