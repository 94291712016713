import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    }
  ]
})
export class DateTimePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() set maxDate(value: any) {
    this._maxDate = this.formatDate(value);
  }
  @Input() rounding: boolean = false;
  @Input() autoIncrementTime = false;

  incrementTimeTimer?: number;
  _date = new Date();

  minDate = this.formatDate(new Date());
  private _maxDate = this.formatDate(new Date('01/01/2050'));
  _formControlDate = new FormControl();

  get maxDate(): any {
    return this._maxDate;
  }

  set date(value: Date) {
    value.setSeconds(0);
    value.setMilliseconds(0);

    this._date = value;
    this._formControlDate.setValue(this.formatDate(value));
  }

  get date(): Date {
    return this._date;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor() { }

  ngOnInit(): void {
    this.onChange(this._date);
    if (this.autoIncrementTime)
      this.incrementTime();
  }

  ngOnDestroy(): void {
    clearTimeout(this.incrementTimeTimer);
  }

  incrementTime() {
    clearTimeout(this.incrementTimeTimer);

    this.incrementTimeTimer = window.setTimeout(() => {
      if (this.formatDate(this._date) < this.formatDate(new Date())) {
        this.date = new Date();
        this.onChange(this._date);
      }

      this.incrementTime();
    }, 5000);
  }

  updatingValue(value: any) {
    this.date = new Date(this._formControlDate.value);
    this.onChange(this._date);
  }

  formatDate(date: Date) {
    const withTwoDigits = (number: number): string => {
      if (number < 10)
        return '0' + number;

      return '' + number;
    }

    return `${date.getFullYear()}-${withTwoDigits(date.getMonth() + 1)}-${withTwoDigits(date.getDate())}T${withTwoDigits(date.getHours())}:${withTwoDigits(date.getMinutes())}`;
  }

  writeValue(obj: any): void {
    if (typeof obj === 'string' || obj instanceof String) {
      let dateAsString = obj.toString();
      this.date = new Date(dateAsString);
    }
    else
      this.date = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
