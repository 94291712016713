<div class="content">
     <img [src]="logoSrc"
          alt=""
          [class.bigger-logo]="!loginRequired">

     <app-top-menu *ngIf="loginRequired"></app-top-menu>

     <app-language-chooser *ngIf="loginRequired || isSelfServiceStation" [language]="language"></app-language-chooser>

     <div class="initials"
          *ngIf="loginRequired">
          <receptionist-initials></receptionist-initials>
     </div>

     <div class="supportMail">
          <a href="mailto:support@commuteapp.dk">Support mail</a>
     </div>
</div>
