import { Subscription } from 'rxjs';
import { Component, Input, OnDestroy } from '@angular/core';
import { HotelService, PaymentOption } from 'src/app/services/hotel.service';
import { PriceService } from 'src/app/services/price.service';
import { ReceptionistInitialsService } from 'src/app/services/receptionist-initials.service';
import { RideType } from 'src/app/models/enums';

@Component({
  selector: 'app-order-button',
  templateUrl: './order-button.component.html',
  styleUrls: ['./order-button.component.scss']
})
export class OrderButtonComponent implements OnDestroy {

  @Input() paymentOption: PaymentOption = PaymentOption.ApplyToBill;
  @Input() isManual = false;

  missingInitials = false;
  usingFixedPrices = false;

  receptionistInitialsRequiredSubscription: Subscription;
  priceFromTaxiCompanySubscription?: Subscription;

  constructor(hotelService: HotelService, receptionistInitialsService: ReceptionistInitialsService, private priceService: PriceService) {
    this.receptionistInitialsRequiredSubscription = hotelService.receptionistInitialsRequired.subscribe(isRequired => {
      if (isRequired)
        receptionistInitialsService.initials.subscribe(initials => {
          this.missingInitials = !initials;
        });
    });

    this.priceFromTaxiCompanySubscription = priceService.priceFromTaxiCompany.subscribe(_ => this.setUsingFixedPrices());
  }

  ngOnDestroy(): void {
    this.receptionistInitialsRequiredSubscription?.unsubscribe();
    this.priceFromTaxiCompanySubscription?.unsubscribe();
  }

  setUsingFixedPrices() {
    this.usingFixedPrices = this.priceService.rideType.value == RideType.FixedPrice || this.priceService.priceFromTaxiCompany.value;
  }

  isApplyToBill() {
    return this.paymentOption === PaymentOption.ApplyToBill;
  }

  isHotelPayment() {
    return this.paymentOption === PaymentOption.HotelPays;
  }

  isPaidInTaxi() {
    return this.paymentOption === PaymentOption.PayInTaxi;
  }

  isPrepaid() {
    return this.paymentOption === PaymentOption.Prepay;
  }

  isPayByTerminal() {
    return this.paymentOption === PaymentOption.PayByTerminal;
  }

  usingFixedPriceButPaidInTheTaxi() {
    return this.isPaidInTaxi() && this.priceService.isFixedDestination == false;
  }
}
