import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { TaxiService } from './taxi.service';
import { catchError, map } from 'rxjs/operators';
import { ITaxiPriceRequest } from '../models/taxiPriceRequest.interface';
import { RideType } from 'src/app/models/enums';

export enum PaymentOptionsAllowed {
  PrepayAllowed = 1,
  PayInTaxiAllowed = 2
}

@Injectable({
  providedIn: 'root'
})

export class GetTaxiService extends TaxiService {

  public hotelInfo$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public taxiConfiguration$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public getTaxiToHotel(body: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8')
    return this.http.post(this.api_url + environment.getTaxiToHotel,
      body, { headers: headers });
  }

  public getTaxiToAirport(body: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8')
    return this.http.post(this.api_url + environment.getTaxiToAirport,
      body, { headers: headers });
  }

  public getOtherDestinations(body: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8')
    return this.http.post(this.api_url + environment.getOtherDestinations,
      body, { headers: headers });
  }

  public getRides(body: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8')
    return this.http.post(this.api_url + environment.getRides,
      body, { headers: headers });
  }

  public getCompletedTaxiCount(body: any): Observable<any> {
    return this.post(environment.getCompletedTaxiCount, body);
  }

  public getRideInfo(body: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8')
    return this.http.post(this.api_url + environment.getRideInfo,
      body, { headers: headers });
  }

  public cancelRide(body: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8')
    return this.http.post(this.api_url + environment.cancelRide,
      body, { headers: headers });
  }

  public getReceiptPDF(rideId: string): Observable<any> {
    return this.http.get(this.api_url + environment.getReciptPDF.replace('{rideId}', rideId));
  }

  public getModelPDF(rideId: string): Observable<any> {
    let url = environment.getModelPDF.replace(`{rideId}`, rideId);
    return this.http.get(this.api_url + url);
  }

  public getReceipts(body: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8')
    return this.http.post(this.api_url + environment.getRecipts,
      body, { headers: headers });
  }

  public applyRide(body: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8')
    return this.http.post(this.api_url + environment.applyRide,
      body, { headers: headers });
  }

  public deleteRide(body: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8')
    return this.http.post(this.api_url + environment.deleteRide,
      body, { headers: headers });
  }

  public getTaxiPrice(body: ITaxiPriceRequest): Observable<any> {
    return this.post(environment.getTaxiPrice, body);
  }

  public getAirportPrice(body: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8')
    return this.http.post(this.api_url + environment.getAirportPrice,
      body, { headers: headers });
  }

  public getFixedDestinations(): Observable<any> {
    return this.post(environment.getFixedDestinations, {}, true, true);
  }

  public getFixedDestinationsExcludingAirports(): Observable<any> {
    return this.post(environment.getFixedDestinationsExcludingAirports, {}, true);
  }

  public getFixedDestinationsByIds(ids: string[]): Observable<any> {
    return this.post(environment.getFixedDestinationsByIds, { ids }, true, true);
  }

  public verifyAdmin(password: string): Observable<any> {
    return this.post(environment.verifyAdmin, { adminPassword: password }, true);
  }

  public getAirportsForHotel(hotelId: string): Observable<any> {
    return this.post(environment.getAirportsForHotel, { hotelId });
  }

  public getAirportInfo(airportId: string): Observable<any> {
    return this.post(environment.getAirportInfo, { airportId: airportId });
  }

  public getHotelConfiguration(): Observable<any> {
    return this.post(environment.getHotelConfiguration, {}, true).pipe(
      map(data => {
        this.hotelInfo$.next(data);
        return data;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );;
  }

  public getTaxiConfiguration(hotelId: string, priorityGroup?: RideType): Observable<any> {
    return this.post(environment.getTaxiConfiguration, { hotelId, priorityGroup }).pipe(
      map(data => {
        this.taxiConfiguration$.next(data);
        return data;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  public getTaxiConfigurationByRide(rideId: string): Observable<any> {
    return this.post(environment.getTaxiConfigurationByRide, { rideId });
  }

  public getFlightInfo(flightNumber: string): Observable<any> {
    return this.post(environment.getFlightInfo, { flightNumber });
  }

  public getUpcomingRides(): Observable<any> {
    return this.post(environment.getUpcomingRides, {}, true);
  }

  public getArrivalsOfTheDay(date?: Date): Observable<any> {
    return this.post(environment.getArrivalsOfTheDay, { date: date }, true);
  }

  public getSopBookingAdditionalInfo(): Observable<any> {
    return this.post(environment.GetSopBookingAdditionalInfo, {}, true);
  }

  public getHotelInfo(hotelId: string): Observable<any> {
    return this.http.get(this.api_url + environment.getHotelInfo.replace('{hotelId}', hotelId));
  }

  public getHotelCountry(hotelId: string): Observable<any> {
    return this.http.get(this.api_url + environment.getHotelCountry.replace('{hotelId}', hotelId), { responseType: 'text' });
  }

  public getTaxiLocations(): Observable<any> {
    return this.post(environment.GetTaxiLocation, {}, true);
  }

  public trackTaxiLocation(trackingCode: string): Observable<any> {
    return this.http.get(this.api_url + environment.getTaxiLocationByTrackingCode.replace('{trackingCode}', trackingCode));
  }

  public getHotelInformationForAdminUrlRedirector(): Observable<any> {
    return this.post(environment.getHotelInformationForAdminUrlRedirector, {}, true, true);
  }

  public getAllHotelDataJson(hotelId: string): Observable<any> {
    return this.post(environment.getAllHotelDataJson, { HotelToCopyId: hotelId }, true, true);
  }

  public setHotelDataJson(hotelJsonData: object): Observable<any> {
    return this.post(environment.setHotelDataFromJson, { hotel: hotelJsonData }, true, true);
  }

  public getHotelSettings(hotelId: string): Observable<any> {
    return this.post(environment.getHotelSettings, { getHotelId: hotelId }, true, true);
  }

  public updateHotelSettings(settings: object, hotelId: string): Observable<any> {
    return this.post(environment.updateHotelSettings, { settings, getHotelId: hotelId }, true, true);
  }

  public getCancelledRidesForApplyToBill(hotelId: string): Observable<any> {
    return this.post(environment.getCancelledRidesForApplyToBill.replace('{hotelId}', hotelId), true);
  }

  public getCancelledRidesByDriver(): Observable<any> {
    return this.post(environment.getCancelledRidesByDriver, {}, true);
  }

  public getCountryRide(rideId: string): Observable<any> {
    return this.http.get(this.api_url + environment.getRideCountry.replace('{rideId}', rideId));
  }

  public getAvergeTime(hotelId: string): Observable<any> {
    return this.post(environment.getAvergeTime, { hotelId }, true);
  }

  public getRatingForHotel(hotelId: string): Observable<any> {
    return this.post(environment.getRatingForHotel, { hotelId }, true);
  }

  public updateRidesReceptionComment(body: any): Observable<any> {
    return this.post(environment.updateRidesReceptionComment, { rideId: body.rideId, receptionComment: body.receptionComment }, true);
  }

  public getRestaurantRides(hotelId: string): Observable<any> {
    return this.post(environment.getRestaurantRides, { hotelId }, true);
  }

  public updateRide(ride: any): Observable<any> {
    return this.post(environment.updateRide, ride, true);
  }

  public getRideToUpdate(rideId: string): Observable<any> {
    return this.post(environment.getRideToUpdate, { rideId: rideId }, true);
  }

  public getRideStatus(rideId: string): Observable<any> {
    return this.post(environment.getRideStatus, { rideId: rideId });
  }
}
