<form [formGroup]="fromAirport"
      (submit)="order()"
      #fromAirportForm="ngForm"
      *ngIf="!messageShow">
  <div class="forms">
    <app-airport-chooser (airportSelected)="airportSelected($event)"></app-airport-chooser>

    <div class="float-container">
      <div class="form-group">
        <ion-label>{{ "flightNo" | translate }}</ion-label>
        <ion-input formControlName="flightNo"
                   type="text"
                   placeholder="e.g. “SK586“"
                   class="form-controls"
                   (keypress)="flightNoKeyPress($event)">
        </ion-input>
      </div>

      <div *ngIf="getCustomerAllowed" class="form-group" style="width: 100px;">
          <ion-label>{{ "roomNo" | translate }}</ion-label>
          <ion-input formControlName="roomNo"
                     type="text"
                     placeholder="“253“"
                     class="form-controls"></ion-input>
      </div>

      <div *ngIf="getCustomerAllowed" style="width: 10px;">          
        <button type="button" id="roomNumberButton" (click)="roomNumberGetInfo()" class="roomNumberButton">✓</button>
      </div>

      <div class="form-group">
        <ion-label>{{ "guestName" | translate }}</ion-label>
        <ion-input formControlName="guestName"
                   type="text"
                   placeholder="{{ 'guest_name_placeholder' | translate }}"
                   class="form-controls"></ion-input>
      </div>
    </div>

            
    <app-apply-to-bill-box *ngIf="showApplyToBill"
      formControlName="paymentOption"
      [showErrors]="formSubmit"
      class="apply-to-bill-box"></app-apply-to-bill-box>

    <div class="alerts">
      <div *ngIf="flightNo && formSubmit"
           class="alert alert-danger">
        <div *ngIf="flightNo?.errors?.required">
          {{ "flightNoRequired" | translate }}
        </div>
      </div>

      <div *ngIf="guestName && formSubmit"
           class="alert alert-danger">
        <div *ngIf="guestName?.errors?.required">
          {{ "guestNameRequired" | translate }}
        </div>
      </div>
    </div>

    <div class="float-container">
      <div class="form-group">
        <ion-label>{{ "phoneNoLabel" | translate }}</ion-label>
        <ion-input formControlName="phoneNo"
                   type="tel"
                   [placeholder]="translate.instant('guest_number_placeholder')"
                   class="form-controls"></ion-input>
        <div *ngIf="phoneNo && formSubmit"
             class="alert alert-danger">
          <div *ngIf="phoneNo?.errors?.required">
            {{ "phoneNoRequired" | translate }}
          </div>
          <div *ngIf="phoneNo?.errors?.minlength">
            {{ "phoneNoMinimum" | translate }}
          </div>
          <div *ngIf="phoneNo?.errors?.pattern">
            {{ "phoneNoValid" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pick-time-and-price">
    <div>
      {{ "flightArrivalTime-optional" | translate }}
      <div>
        <app-date-time-picker formControlName="pickupTime" (change)="onChangeDate()"></app-date-time-picker>
      </div>
    </div>

    <div *ngIf="isManual">
      {{ "guestPrice" | translate }}
      <div>
        <input type="number" min="0"
               class="form-controls ion-text-right"
               formControlName="taxiPrice"
               (keyup)="onChangePrice()" (change)="onChangePrice()"/>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!disableOptions()">
  <ng-container *ngIf="!isManual">
  {{ "possibilityToOrder" | translate }}
</ng-container>
  <div *ngIf="!isManual" class="taxiInfo">
    <div class="options-table">
      <mat-checkbox aria-label="Select an option"
                    formControlName="largeTaxi"
                    color="primary"
                    *ngIf="displayOption('large')">
        {{ "fixedDestination-largeTaxiDescription" | translate }}
      </mat-checkbox>

      <mat-checkbox aria-label="Select an option"
                    (change)="isShowShare($event)"
                    formControlName="stationCar"
                    color="primary"
                    *ngIf="displayOption('estate car')">{{ "stationCar" | translate }}
      </mat-checkbox>

      <mat-checkbox aria-label="Select an option"
                    (change)="isShowShare($event)"
                    formControlName="electricCar"
                    color="primary"
                    *ngIf="displayOption('electric car')">{{ "electricCarLabel" | translate }}
      </mat-checkbox>

      <mat-checkbox aria-label="Select an option"
                    (change)="isShowShare($event)"
                    formControlName="animal"
                    color="primary"
                    *ngIf="displayOption('bring pet')">{{ "animalLabel" | translate }}
      </mat-checkbox>

      <mat-checkbox aria-label="Select an option"
                    (change)="isShowShare($event)"
                    formControlName="bike"
                    color="primary"
                    *ngIf="displayOption('bring bike')">{{ "bikeLabel" | translate }}
      </mat-checkbox>

      <app-hotel-payment *ngIf="displayOption('hotel pays')" (hotelPayment)="hotelPaymentChanged($event)"></app-hotel-payment>

      <div class="number" *ngIf="displayOption('number of cars')">
        <ion-label>{{ "numberOfCarsLabel" | translate }}</ion-label>
        <ion-input max="25"
                   min="1"
                   type="number"
                   formControlName="numberOfCars"
                   class="form-control with-outline"
                   [ngClass]="{'alert alert-danger': numberOfCars?.errors?.required || numberOfCars?.errors?.min || numberOfCars?.errors?.max}"></ion-input>
      </div>

      <div class="large" *ngIf="displayOption('comment')">
        <ion-label>{{ "commentToDriverLabel" | translate }}</ion-label>
        <ion-input formControlName="commentToDriver"
                   maxlength="200"
                   type="text"
                   placeholder="e.g. “”"
                   class="form-control with-outline"></ion-input>
      </div>
    </div>

    <div *ngIf="largeTaxi && formSubmit"
         class="alert alert-danger">
      <div *ngIf="largeTaxi?.errors?.required">
        {{ "pleaseSelectOption" | translate }}
      </div>
    </div>
  </div>
  </ng-container>

  <div  class="taxiInfo"
       *ngIf="showOnLarge">
    <h2>
      <mat-icon>person</mat-icon> {{ "chooseNumberOfGuests" | translate }}
    </h2>
    <div class="innercontainer">
      <div class="guests">
        <span class="steps">4</span>
        <span class="steps">5</span>
        <span class="steps">6</span>
        <span class="steps">7</span>
      </div>
      <mat-slider class="customSlide"
                  thumb
                  tickInterval="1"
                  min="4"
                  max="7"
                  formControlName="numberOfGuests"
                  [value]="1"></mat-slider>
    </div>
  </div>

  <div *ngIf="showOnLarge != true 
    && !fromAirport.value.hotelPayment 
    && taxiCanShareTaxi 
    && hotelSharesTaxi 
    && applyToBill()">
    {{ "guestWishToShare" | translate }}
    <div class="taxiInfo">
      <p>
        {{ "availableRide" | translate | replace: "savings":sharedRideSavings | replace: "currency":currency }}
      </p>
      <mat-radio-group color="primary"
                       aria-label="Select an option"
                       formControlName="shareTaxi">
        <mat-radio-button (change)="isShow($event)"
                          [value]="true">{{
          "yes" | translate
          }}</mat-radio-button>
        <mat-radio-button (change)="isShow($event)"
                          [value]="false">{{
          "no" | translate
          }}</mat-radio-button>
      </mat-radio-group>
      <div *ngIf="shareTaxi && formSubmit"
           class="alert alert-danger">
        <div *ngIf="shareTaxi?.errors?.required">
          {{ "pleaseSelectOption" | translate }}
        </div>
      </div>
    </div>
  </div>

  <app-order-button [paymentOption]="paymentOption?.value"></app-order-button>

</form>
<div class="taxi-ordered thankyou" *ngIf="messageShow">
  <div *ngIf="showLargeTaxiLoading">
    <div *ngIf="loadingDone==false">
      <h3>{{ "processingLargeTaxiOrder" | translate }}</h3>
      <mat-progress-bar [mode]="'determinate'" [value]="progressValue" class="progress-bar">
      </mat-progress-bar>
    </div>
    <div *ngIf="loadingDone">
      <h3>{{ largeTaxiCarResponse }}</h3>
    </div>
  </div>
  <div *ngIf="showLargeTaxiLoading==false">
    <h3>{{ "thankYouForYourOrderShort" | translate }}</h3>
  </div>
</div>